import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Select as BaseSelect, FormControl, FormHelperText, MenuItem, Skeleton } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

import { useGetCommonFilterCodeQuery } from 'api/commonCodeApi';
import { useEffect } from 'react';
import { useState } from 'react';

//REACT HOOK FORM - COMMON CODE SELECT
const FormCmmCodeSelect = forwardRef(
    ({ seq, groupName, error, loading, disabled, label, fullWidth, all, defaultOption, helperText, customData, width, ...props }, _) => {
        const { data, isLoading } = useGetCommonFilterCodeQuery(groupName);
        const [helperTextData, setHelperText] = useState(false);
        const { t, i18n } = useTranslation();
        useEffect(() => {
            setHelperText(helperText);
        }, [helperText]);

        if (isLoading || loading) return <Skeleton animation="wave" height={60} sx={{ borderRadius: '12px' }} />;

        return (
            <FormControl
                error={helperTextData ? true : false}
                size="medium"
                sx={{ minWidth: { md: 200, xs: '100%' }, width: { md: width ? width : '251px', xs: 'auto' } }}
                disabled={disabled}
                fullWidth={fullWidth}
            >
                <BaseSelect
                    size="small"
                    svariant="outlined"
                    input={<OutlinedInput />}
                    {...props}
                    label={props.placeholder ?? ''}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                >
                    {defaultOption === 'select' ? (
                        <MenuItem value="0">{t('select')}</MenuItem>
                    ) : (
                        all && <MenuItem value="ALL">{t('All')}</MenuItem>
                    )}
                    {(customData ?? data?.data)?.map((value, i) => (
                        <MenuItem key={i} value={seq ? value.seq : value.code}>
                            {i18n.language === 'kr' ? value.codeNm : value.codeEngNm}
                        </MenuItem>
                    ))}
                </BaseSelect>

                <FormHelperText error id={seq} sx={{ position: 'absolute', bottom: -18, fontSize: 11, left: -10 }}>
                    {helperTextData ? helperTextData : ' '}
                </FormHelperText>
            </FormControl>
        );
    }
);
export default FormCmmCodeSelect;
