import { CommonCodeFilterApi } from 'api/commonCodeApi';
import { DashboardInfoApi } from 'api/dashboard/DashboardApi';
import { AuthApi } from 'views/pages/authentication/auth-forms/AuthApi';
import {
    AdminConfig,
    BannerConfig,
    CommonCodeConfig,
    ContentConfig,
    ContentSettingsConfig,
    ContentMenuConfig,
    FaqBoardConfig,
    PopupConfig,
    QnaConfig,
    UserConfig,
    UserMenuConfig,
    AdminMenuConfig,
    SiteInfoConfig,
    SystemSettingConfig,
    RoleManageConfig,
    MailConfig,
    SendMailConfig,
    AccountSettingsConfig,
    MembershipConfig,
    WorkerConfig,
    ProjectPaymentConfig,
    SkillConfig,
    ConsultingConfig,
    OngoingConfig,
    DevelopmentCompletedConfig,
    CompletedConfig,
    ProjectConfig,
    FreeBoardConfig,
    ReviewConfig,
    NoticeBoardConfig,
    InquiryBoardConfig,
    PartnerBoardConfig
} from 'views';

export const Middlewares = [
    AuthApi.middleware,
    BannerConfig.API.middleware,
    ContentConfig.API.middleware,
    AdminConfig.API.middleware,
    UserConfig.API.middleware,
    SiteInfoConfig.API.middleware,
    SystemSettingConfig.API.middleware,
    RoleManageConfig.API.middleware,
    PopupConfig.API.middleware,
    FaqBoardConfig.API.middleware,
    QnaConfig.API.middleware,
    UserMenuConfig.API.middleware,
    AdminMenuConfig.API.middleware,
    CommonCodeConfig.API.middleware,
    ContentSettingsConfig.API.middleware,
    ContentMenuConfig.API.middleware,
    CommonCodeFilterApi.middleware,
    MailConfig.API.middleware,
    SendMailConfig.API.middleware,
    DashboardInfoApi.middleware,
    AccountSettingsConfig.API.middleware,
    MembershipConfig.API.middleware,
    WorkerConfig.API.middleware,
    ProjectPaymentConfig.API.middleware,
    SkillConfig.API.middleware,
    ConsultingConfig.API.middleware,
    OngoingConfig.API.middleware,
    DevelopmentCompletedConfig.API.middleware,
    CompletedConfig.API.middleware,
    ProjectConfig.API.middleware,
    FreeBoardConfig.API.middleware,
    ReviewConfig.API.middleware,
    NoticeBoardConfig.API.middleware,
    InquiryBoardConfig.API.middleware,
    PartnerBoardConfig.API.middleware
];
