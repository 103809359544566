import { useState } from 'react';
import { AVAIL_LANG } from 'config';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Menu, MenuItem, Typography, styled } from '@mui/material';

// assets
import { IconWorld } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import EngLang from 'assets/images/icons/eng.png';
import KorLang from 'assets/images/icons/kor.png';

// ==============================|| NOTIFICATION ||============================== //

const LangAvatar = styled(Avatar)((theme) => ({
    width: '20px',
    height: '20px',
    fontSize: '1.25rem',
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: '8px'
}));

const LanguageSection = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    function changeLocale(l) {
        i18next.changeLanguage(l);
        handleClose();
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 2,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Typography sx={{ borderRadius: '12px' }}>
                    <Button
                        variant="rounded"
                        sx={{
                            transition: 'all .2s ease-in-out',
                            color: theme.palette.text.hint,
                            fontWeight: '600',
                            '&[aria-controls="menu-list-grow"],&:hover': {}
                        }}
                        onClick={handleClick}
                        color="inherit"
                    >
                        <LangAvatar
                            src={t('curlangCode') === 'en' ? EngLang : t('curlangCode') === 'kr' ? KorLang : ''}
                            variant="circular"
                        />
                        &nbsp; {t('curlang')}
                    </Button>
                </Typography>
            </Box>
            <Menu
                id="menu-language"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {AVAIL_LANG.map((lang, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            changeLocale(lang.code);
                        }}
                    >
                        <LangAvatar src={lang?.code === 'en' ? EngLang : lang?.code === 'kr' ? KorLang : ''} variant="circular" />
                        {lang.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default LanguageSection;
