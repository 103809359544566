import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { ConsultingApi } from 'api/project/consulting';

const ConsultingListPage = Loadable(lazy(() => import('./list')));
const ConsultingEditPage = Loadable(lazy(() => import('../ProjectComponents/DetailForm/edit')));

const ConsultingConfig = {
    routes: [
        {
            path: 'consulting',
            children: [
                {
                    path: '',
                    element: <ConsultingListPage />
                },
                {
                    path: ':code',
                    element: <ConsultingEditPage />
                }
            ]
        }
    ],
    API: ConsultingApi
};

export default ConsultingConfig;
