import { useTranslation } from 'react-i18next';
import { CircularProgress, Button, styled } from '@mui/material';
import { SaveAsOutlined } from '@mui/icons-material';
import React from 'react';

const BaseButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.dark.button,
    ...theme.typography.actionBottom,
    '&:hover': {
        backgroundColor: theme.palette.dark.button
    }
}));

const SaveButton = ({ loading, ...props }) => {
    const { t } = useTranslation();

    return (
        <>
            <BaseButton
                type="submit"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color="light" /> : <SaveAsOutlined />}
                {...props}
            >
                {props?.text || t('act_save')}
            </BaseButton>
        </>
    );
};
export default SaveButton;
