import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const ProjectApi = createApi({
    reducerPath: 'ProjectApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['Projects'],
    endpoints: (build) => ({
        // PROJECT
        getProjects: build.query({
            query: (params) => `${URL.PROJECT_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getProject: build.query({
            query: (params) => `${URL.PROJECT_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        getProjectContract: build.query({
            query: (params) => `${URL.PROJECT_CONTRACT_DETAIL}/${params}`,
            providesTags: ['ProjectContractDetail']
        }),
        createProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        updateProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_DELETE}/${body}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),

        // COMMENT
        updateProjectComment: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_COMMENT_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),
        createProjectComment: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_COMMENT_CREATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),
        deleteProjectComment: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_COMMENT_DELETE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),

        // ITEM
        createProjectItem: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_ITEM_CREATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),
        updateProjectItem: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_ITEM_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),
        deleteProjectItem: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_ITEM_DELETE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),
        deleteProjectItemWorker: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_ITEM_WORKER_DELETE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),
        // PROJECT DETAIL
        updateProjectDetail: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_DETAIL_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),
        // PROJECT MAIL
        updateProjectMail: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_MAIL_SEND}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),

        //PROJECT - ONGOING
        updateProjectActualDate: build.mutation({
            query: (body) => ({
                url: `${URL.ONGOING_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),
        updateProjectItemOngoing: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_ONGOING_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),

        // PROJECT - Completed
        updateProjectSource: build.mutation({
            query: (body) => ({
                url: `${URL.COMPLETED_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),

        updateProjectProceed: build.mutation({
            query: (body) => ({
                url: `${URL.CONSULT_PROJECT_PROCEED}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL', 'LIST']
        }),
        getCurrentTime: build.query({
            query: () => `${URL.CURRENT_TIME}`,
            providesTags: ['CURRENT_TIME']
        }),
        updateProjectLink: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_LINK_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL', 'LIST']
        }),
        matchUserToProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_MATCH_USER}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL', 'LIST']
        }),
        // INFORMATION
        getUserProjectInformation: build.query({
            query: (params) => ({
                url: URL.USER_PROJECT_INFORMATION_LIST,
                method: 'GET',
                params: params
            })
        }),
        getWorkerProjectInformation: build.query({
            query: (params) => ({
                url: URL.WORKER_PROJECT_INFORMATION_LIST,
                method: 'GET',
                params: params
            })
        }),
        // DISCOUNT
        updateProjectDiscount: build.mutation({
            query: (body) => ({
                url: `${URL.DISCOUNT_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),
        changeToPreviousStatus: build.mutation({
            query: (body) => ({
                url: `${URL.CHANGE_TO_PREVIOUS_STATUS}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        }),
        getProjectComments: build.query({
            query: (params) => ({
                url: URL.PROJECT_COMMENTS,
                method: 'GET',
                params: params
            })
        })
    })
});

export const {
    // PROJECT
    useGetProjectsQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
    useGetProjectQuery,
    useGetProjectContractQuery,
    useGetProjectCommentsQuery,
    // PROJECT DETAIL
    useUpdateProjectDetailMutation,
    useGetUserProjectInformationQuery,
    useGetWorkerProjectInformationQuery,
    // COMMENT
    useUpdateProjectCommentMutation,
    useCreateProjectCommentMutation,
    useDeleteProjectCommentMutation,
    // ITEM
    useCreateProjectItemMutation,
    useUpdateProjectItemMutation,
    useDeleteProjectItemMutation,
    useDeleteProjectItemWorkerMutation,
    // PROJECT MAIL
    useUpdateProjectMailMutation,
    //PROJECT - ONGOING
    useUpdateProjectActualDateMutation,
    useUpdateProjectItemOngoingMutation,
    // PROJECT COMPLETED
    useUpdateProjectSourceMutation,
    useUpdateProjectProceedMutation,
    useGetCurrentTimeQuery,
    useUpdateProjectLinkMutation,
    useMatchUserToProjectMutation,
    // DISCOUNT
    useUpdateProjectDiscountMutation,
    useChangeToPreviousStatusMutation
} = ProjectApi;
