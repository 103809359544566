import React, { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { FormCmmCodeSelect, SearchInput } from 'components';
import { Controller, useForm } from 'react-hook-form';

const CustomSearchFilter = forwardRef(({ filters, setOptions, formDefaultValues, isLoading, isFetching, placeholder }, _) => {
    const { control, getValues, setValue, watch } = useForm({
        defaultValues: formDefaultValues ?? { searchWrd: '' }
    });

    if (!filters) {
        filters = [];
    }

    const handleSearch = () => {
        const formValues = getValues();
        setOptions((prevState) => ({ ...prevState, ...formValues }));
    };

    return (
        <Grid container sx={{ display: 'flex', gap: '10px', paddingBottom: '15px', justifyContent: 'flex-end' }}>
            {filters && filters.length > 0 && (
                <Grid item display={'flex'} gap={1}>
                    {filters?.map((filter, index) => {
                        return (
                            <Grid key={`filter-${index}`} container display={'flex'} alignItems={'center'}>
                                <Grid item sx={{ display: 'flex' }}>
                                    {filter?.type === 'select' && filter?.commonCode && (
                                        <FormCmmCodeSelect
                                            groupName={filter?.commonCode}
                                            value={watch(filter?.name)}
                                            changedVal={(e) => {
                                                setValue(filter?.name, e);
                                                setOptions((prevState) => ({ ...prevState, [filter?.name]: e }));
                                            }}
                                            disabled={isLoading || isFetching}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
            <Grid item>
                <Controller
                    control={control}
                    name="searchWrd"
                    render={({ field }) => (
                        <SearchInput placeholder={placeholder} size="small" onClick={() => handleSearch()} disabled={isLoading || isFetching} {...field} />
                    )}
                />
            </Grid>
        </Grid>
    );
});

export default CustomSearchFilter;
