import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { ProjectApi } from 'api/project/project';

const OngoingListPage = Loadable(lazy(() => import('./list')));
const OngoingEditPage = Loadable(lazy(() => import('./edit')));

const OngoingConfig = {
    routes: [
        {
            path: 'ongoing',
            children: [
                {
                    path: '',
                    element: <OngoingListPage />
                },
                {
                    path: 'modify/:code',
                    element: <OngoingEditPage />
                }
            ]
        }
    ],
    API: ProjectApi
};

export default OngoingConfig;
