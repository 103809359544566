import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, Button, styled } from '@mui/material';
import { DeleteForeverOutlined } from '@mui/icons-material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { red } from '@mui/material/colors';

import { DeleteConfirm } from 'components/index';
import resultChecker from 'utils/functions/resultChecker';

const BaseButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.error.button,
    ...theme.typography.actionBottom,
    '&:hover': {
        backgroundColor: theme.palette.error.button
    }
}));

const DeleteButton = ({ loading, deleteFunc, to, code, codeGroup, onClose, iconButton, ...props }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [onDelete, deleteResult] = deleteFunc();

    const handleDelete = useCallback(
        (values) => {
            onDelete(values)
                .then((res) => {
                    resultChecker({ res, redirect: () => navigate(to), type: 'delete' });
                    setShow(false); // set Show to false on successful deletion
                })
                .catch((err) => console.log('useUpdateBannerMutation ERROR  => ', err));
        },
        [navigate, onDelete, to]
    );

    return (
        <>
            {!iconButton ? (
                <BaseButton
                    type="button"
                    color="error"
                    startIcon={loading ? <CircularProgress size={20} color="light" /> : <DeleteForeverOutlined />}
                    onClick={() => setShow(true)}
                    {...props}
                >
                    {t('act_delete')}
                </BaseButton>
            ) : (
                <IconButton edge="end" sx={{ marginLeft: 2 }} aria-label="delete" onClick={() => setShow(true)}>
                    <DeleteTwoToneIcon sx={{ color: red[400] }} />
                </IconButton>
            )}
            {show && (
                <DeleteConfirm
                    show={show}
                    setShow={setShow}
                    isLoading={loading || deleteResult?.isLoading}
                    onDelete={handleDelete}
                    code={code}
                    codeGroup={codeGroup}
                />
            )}
        </>
    );
};
export default DeleteButton;
