import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { ProjectApi } from 'api/project/project';

const ConsultingListPage = Loadable(lazy(() => import('./list')));

const ProjectCommentConfig = {
    routes: [
        {
            path: 'comments',
            children: [
                {
                    path: '',
                    element: <ConsultingListPage />
                }
            ]
        }
    ],
    API: ProjectApi
};

export default ProjectCommentConfig;
