import React, { lazy } from 'react';
import MainLayout from './components/layout/MainLayout';
import {
    AdminConfig,
    BannerConfig,
    CommonCodeConfig,
    ContentConfig,
    ContentSettingsConfig,
    FaqBoardConfig,
    PopupConfig,
    QnaConfig,
    UserConfig,
    UserMenuConfig,
    AdminMenuConfig,
    SiteInfoConfig,
    SystemSettingConfig,
    RoleManageConfig,
    ContentMenuConfig,
    MailConfig,
    SendMailConfig,
    AccountSettingsConfig,
    MembershipConfig,
    WorkerConfig,
    ConsultingConfig,
    OngoingConfig,
    DevelopmentCompletedConfig,
    CompletedConfig,
    ContractConfig,
    ProjectPaymentConfig,
    ProjectConfig,
    SkillConfig,
    FreeBoardConfig,
    ReviewConfig,
    NoticeBoardConfig,
    InquiryBoardConfig,
    PartnerBoardConfig,
    ProjectCommentConfig
} from './views';
import MinimalLayout from 'components/layout/MinimalLayout';
import Loadable from 'components/loading/Loadable';
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/auth-forms/Login')));
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const ViewItemsHtml = Loadable(lazy(() => import('views/pages/Project/ProjectComponents/viewItemsHtml')));
const ContractHtml = Loadable(lazy(() => import('views/pages/Project/ProjectComponents/contractHtml')));

export const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard/default',
            element: <Dashboard />
        },
        {
            path: 'user',
            children: [...UserMenuConfig.routes]
        },
        {
            path: 'admin',
            children: [...AdminMenuConfig.routes]
        },
        {
            path: 'member',
            children: [...AdminConfig.routes, ...UserConfig.routes, ...AccountSettingsConfig.routes]
        },
        {
            path: 'manage',
            children: [
                ...BannerConfig.routes,
                ...PopupConfig.routes,
                ...ContentConfig.routes,
                ...ContentSettingsConfig.routes,
                ...ContentMenuConfig.routes,
                ...MembershipConfig.routes,
                ...WorkerConfig.routes,
                ...ReviewConfig.routes,
                ...PartnerBoardConfig.routes
            ]
        },
        {
            path: 'common',
            children: [...CommonCodeConfig.routes, ...MailConfig.routes, ...SendMailConfig.routes]
        },
        {
            path: 'community',
            children: [
                ...QnaConfig.routes,
                ...FaqBoardConfig.routes,
                ...FreeBoardConfig.routes,
                ...NoticeBoardConfig.routes,
                ...InquiryBoardConfig.routes
            ]
        },
        {
            path: 'project/payment',
            children: [...ProjectPaymentConfig.routes]
        },
        {
            path: 'project',
            children: [
                ...ProjectConfig.routes,
                ...ConsultingConfig.routes,
                ...OngoingConfig.routes,
                ...CompletedConfig.routes,
                ...ContractConfig.routes,
                ...DevelopmentCompletedConfig.routes,
                ...ProjectCommentConfig.routes
            ]
        },
        {
            path: 'settings',
            children: [...SiteInfoConfig.routes, ...SystemSettingConfig.routes, ...RoleManageConfig.routes, ...SkillConfig.routes]
        }
    ]
};

export const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login/',
            element: <AuthLogin3 />
        },
        {
            path: 'project/items/print/:code',
            element: <ViewItemsHtml />
        },
        {
            path: 'project/contract/print/:code',
            element: <ContractHtml />
        }
    ]
};
