// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `#ffffff`,
	"primaryLight": `#e3f2fd`,
	"primary200": `#90caf9`,
	"primaryMain": `#5d87ff`,
	"primaryDark": `#4570ea`,
	"primary800": `#1565c0`,
	"secondaryLight": `#f7faff`,
	"secondary200": `#b39ddb`,
	"secondaryMain": `#673ab7`,
	"secondaryDark": `#5b5b98`,
	"secondary800": `#4527a0`,
	"successLight": `#b9f6ca`,
	"success200": `#69f0ae`,
	"successMain": `#13deb9`,
	"successDark": `#02b3a9`,
	"errorLight": `#ef9a9a`,
	"errorMain": `#fa896b`,
	"errorDark": `#f3704d`,
	"orangeLight": `#fbe9e7`,
	"orangeMain": `#ff8a54`,
	"orangeDark": `#d84315`,
	"warningLight": `#fff8e1`,
	"warningMain": `#ffae1f`,
	"warningDark": `#ae8e59`,
	"grey50": `#fafafa`,
	"grey100": `#f8f9fa`,
	"grey200": `#ecf2ff`,
	"grey300": `#d0dbe1`,
	"grey500": `#9e9e9e`,
	"grey600": `#757575`,
	"grey700": `#616161`,
	"grey900": `rgba(0, 0, 0, 0.8705882353)`,
	"darkPaper": `#111936`,
	"darkBackground": `#1a223f`,
	"greyPaper": `#f3f5f7`,
	"greenRGBA": `rgba(0, 182, 155, 0.1)`,
	"orangeRGBA": `rgba(255, 138, 84, 0.08)`,
	"blueRGBA": `rgba(45, 182, 245, 0.08)`,
	"heraBlueRGBA": `rgba(117, 127, 239, 0.1)`,
	"redBlueRGBA": `rgba(238, 54, 140, 0.1)`,
	"greyRGBA": `rgb(108, 117, 125)`,
	"primaryButtonDark": `rgb(63, 81, 181)`,
	"primaryButtonError": `rgba(244, 67, 54, 0.8)`,
	"darkLevel1": `#29314f`,
	"darkLevel2": `#212946`,
	"darkTextTitle": `#d7dcec`,
	"darkTextPrimary": `#bdc8f0`,
	"darkTextSecondary": `#8492c4`,
	"darkTextHint1": `#2a3547`,
	"darkTextHint2": `#67748e`,
	"darkTextHint3": `#3a416f`,
	"darkPrimaryLight": `#e3f2fd`,
	"darkPrimaryMain": `#49beff`,
	"darkPrimaryMidle": `#2db6f5`,
	"darkPrimaryDark": `#23afdb`,
	"darkPrimary200": `#90caf9`,
	"darkPrimary800": `#1565c0`,
	"darkSecondaryLight": `#d1c4e9`,
	"darkSecondaryMain": `#7c4dff`,
	"darkSecondaryDark": `#651fff`,
	"darkSecondary200": `#b39ddb`,
	"darkSecondary800": `#6200ea`
};
export default ___CSS_LOADER_EXPORT___;
