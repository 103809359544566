import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from 'utils/statics/constant';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const [open, setOpen] = useState(false);

    const container = window !== undefined ? () => window.document.body : undefined;

    useEffect(() => {
        setOpen(drawerOpen);
    }, [drawerOpen]);

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'permanent' : 'temporary'}
                anchor="left"
                open={open}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: open ? drawerWidth : 'auto',
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        },
                        ...(!open && {
                            transition: theme.transitions.create('width', {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen
                            }),
                            width: '80px',
                            [theme.breakpoints.up('sm')]: {
                                width: theme.spacing(9)
                            }
                        })
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                        <LogoSection />
                    </Box>
                </Box>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)'
                    }}
                >
                    <MenuList />
                </PerfectScrollbar>
            </Drawer>
        </Box>
    );
};

export default Sidebar;
