export default function themeTypography(theme) {
    return {
        fontFamily: theme?.fontFamily,
        h6: {
            fontWeight: 400,
            color: theme.heading,
            fontSize: '0.75rem'
        },
        h5: {
            fontSize: '0.875rem',
            color: theme.heading,
            fontWeight: 500
        },
        h4: {
            fontSize: '1rem',
            color: theme.heading,
            fontWeight: 600
        },
        h3: {
            fontSize: '1.25rem',
            color: theme.heading,
            fontWeight: 600
        },
        h2: {
            fontSize: '1.5rem',
            color: theme.heading,
            fontWeight: 700
        },
        h1: {
            fontSize: '2.125rem',
            color: theme.heading,
            fontWeight: 700
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.textDark
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: theme.darkTextSecondary
        },
        caption: {
            fontSize: '0.75rem',
            color: theme.darkTextSecondary,
            fontWeight: 400
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em'
        },
        body2: {
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: '1.5em',
            color: theme.darkTextPrimary
        },
        button: {
            textTransform: 'capitalize'
        },
        borderRadiusSmall: {
            borderRadius: theme.smallBorderRadius
        },
        borderRadiusMedium: {
            borderRadius: theme.secondBorderRadius
        },
        customInput: {
            marginTop: 1,
            marginBottom: 1,
            '& > label': {
                top: 23,
                left: 0,
                color: theme.grey500,
                '&[data-shrink="false"]': {
                    top: 5
                }
            },
            '& > div > input': {
                padding: '30.5px 14px 11.5px !important'
            },
            '& legend': {
                display: 'none'
            },
            '& fieldset': {
                top: 0
            }
        },
        mainContent: {
            backgroundColor: theme.backgroundDefault,
            width: '100%',
            minHeight: 'calc(100vh - 68px)',
            flexGrow: 1,
            padding: '20px',
            marginTop: '68px',
            marginRight: '20px',
            borderRadius: `${theme?.secondBorderRadius}px`
        },
        menuCaption: {
            paddingLeft: '24px',
            textTransform: 'uppercase',
            lineHeight: '1.25',
            fontSize: '0.75rem',
            color: theme.colors.darkTextHint2,
            opacity: '0.6',
            fontWeight: 700
        },
        subMenuCaption: {
            fontSize: '0.6875rem',
            fontWeight: 400,
            color: theme.colors.darkTextHint2,
            textTransform: 'capitalize'
        },
        smallAvatar: {
            width: '22px',
            height: '22px',
            fontSize: '1rem'
        },
        mediumAvatar: {
            width: '34px',
            height: '34px',
            fontSize: '1.2rem',
            cursor: 'pointer'
        },
        outlinedIconGreen: {
            backgroundColor: '#e6fffa',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            border: 'none',
            '& .MuiChip-icon': {
                color: '#13deb9'
            }
        },
        largeAvatar: {
            width: '44px',
            height: '44px',
            fontSize: '1.5rem',
            cursor: 'pointer'
        },
        boxBoxShadow: {
            boxShadow: theme.boxBoxShadow
        },
        boxBorder: {
            boxShadow: theme.mainBoxShadow,
            backgroundColor: theme.colors.paper,
            border: 'none',
            borderRadius: theme.mainBorderRadius,
            overflowWrap: 'break-word',
            overflow: 'hidden',
            color: theme.darkTextPrimary
        },
        actionBottom: {
            height: 41,
            minWidth: 150,
            fontWeight: 600,
            color: theme.paper,
            padding: '14px 25px',
            fontSize: '14px',
            '& .MuiButton-startIcon .MuiSvgIcon-root': {
                fontSize: 20,
                fontWeight: '600'
            }
        },
        coloredBadge: {
            borderRadius: theme.smallBorderRadius,
            lineHeight: '1.4em',
            height: 'auto',
            fontWeight: '500',
            '& .MuiChip-label': {
                padding: '2px 13px 3px'
            }
        },
        greenBadge: {
            color: theme.colors.successDark,
            backgroundColor: theme.colors.greenRGBA
        },
        orangeBadge: {
            color: theme.colors.orangeMain,
            backgroundColor: theme.colors.orangeRGBA
        },
        blueBadge: {
            color: theme.colors.darkPrimaryMidle,
            backgroundColor: theme.colors.blueRGBA
        },
        heraBlueBadge: {
            color: theme.colors.primaryDark,
            backgroundColor: theme.colors.heraBlueRGBA
        },
        redBadge: {
            color: theme.colors.errorMain,
            backgroundColor: theme.colors.redBlueRGBA
        },
        greyBadge: {
            color: theme.colors.greyRGBA,
            backgroundColor: theme.colors.grey100
        }
    };
}
