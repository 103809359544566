import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { AdminApi } from 'api/manage/admin';

const AdminListPage = Loadable(lazy(() => import('./list')));
const AdminCreatePage = Loadable(lazy(() => import('./create')));
const AdminEditPage = Loadable(lazy(() => import('./edit')));

const AdminConfig = {
    routes: [
        {
            path: 'admin',
            children: [
                {
                    path: '',
                    element: <AdminListPage />
                },
                {
                    path: 'create',
                    element: <AdminCreatePage />
                },
                {
                    path: 'modify/:code',
                    element: <AdminEditPage />
                }
            ]
        }
    ],
    API: AdminApi
};

export default AdminConfig;
