import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, styled } from '@mui/material';

const BaseButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.button,
    ...theme.typography.actionBottom,
    '&:hover': {
        backgroundColor: theme.palette.primary.main
    }
}));

const CreateButton = ({ to, state, ...props }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <BaseButton startIcon={<AddRoundedIcon />} type="button" onClick={() => navigate(to)} {...props}>
            {t('act_create')}
        </BaseButton>
    );
};

export default CreateButton;
