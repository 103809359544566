import { OutlinedInput, Skeleton } from '@mui/material';
import { forwardRef } from 'react';

const BasicField = forwardRef(({ width, sx, isLoading, ...other }, ref) => {
    return isLoading ? (
        <Skeleton animation="wave" width={'200px'} height={'25px'} sx={{ borderRadius: '4px' }} />
    ) : (
        <OutlinedInput ref={ref} size="small" sx={{ width: { md: width ? width : '251px', xs: 'auto' }, ...sx }} {...other} />
    );
});

export default BasicField;
