export default function componentStyleCustom() {
    return {
        formGridContainer: {
            borderRight: '1px solid #e3f2fd',
            borderBottom: '1px solid #e3f2fd'
        },
        formGridLabel: {
            p: 1,
            borderRight: '1px solid #e3f2fd',
            borderLeft: '1px solid #e3f2fd',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
            color: '#212121',
            lineHeight: 1.75,
            fontSize: '14px',
            padding: '8px',
            backgroundColor: '#f5f5f5'
        }
    };
}
