import { Box, styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Modal } from '@mui/material';

import { IconCheck } from '@tabler/icons';
import { t } from 'i18next';

const StyledBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    backgroundColor: '#FFFFFF',
    paddingBottom: '20px',
    paddingRight: '20px',
    paddingLeft: '30px',
    paddingTop: '14px',
    borderRadius: '5px'
});

const CustomConfirmModal = ({ show, title, setShow, modalConfirm }) => {
    return (
        <Modal open={show} onClose={() => setShow(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <StyledBox>
                <p id="parent-modal-description">{title || t('ask_to_create')}</p>
                <Grid textAlign="right" marginTop={4}>
                    <LoadingButton
                        style={{ marginRight: 12 }}
                        size="small"
                        loadingPosition="start"
                        startIcon={<IconCheck />}
                        variant="contained"
                        onClick={() => {
                            modalConfirm();
                        }}
                    >
                        {t('act_confirm')}
                    </LoadingButton>
                    <Button
                        disableElevation
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        {t('act_cancel')}
                    </Button>
                </Grid>
            </StyledBox>
        </Modal>
    );
};

export default CustomConfirmModal;
