import { IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { useEffect, forwardRef } from 'react';
import { useState } from 'react';
import { addComma } from 'utils/functions/common';
import SaveIcon from '@mui/icons-material/Save';
import BasicField from './BasicField';

const PriceInput = forwardRef(({ defaultValue, sx, setValue, onClickButton, submitButton, text, ...props }, _) => {
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        if (defaultValue) {
            setInputValue(defaultValue);
        } else if (defaultValue === 0 || !defaultValue) {
            setInputValue('');
        }
    }, [defaultValue]);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = parseFloat(inputValue.replace(/,/g, ''));

        if (!isNaN(numericValue)) {
            setInputValue(addComma(numericValue));
            setValue(numericValue);
        } else {
            setInputValue('');
            setValue('');
        }
    };

    return (
        <BasicField
            onChange={handleInputChange}
            value={inputValue}
            endAdornment={
                submitButton && (
                    <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => onClickButton()} edge="end">
                            <SaveIcon />
                            {text && <Typography>{text}</Typography>}
                        </IconButton>
                    </InputAdornment>
                )
            }
            aria-invalid="false"
            inputProps={{
                style: { textAlign: 'right' }
            }}
            sx={{ ...sx }}
            {...props}
        />
    );
});

export default PriceInput;
