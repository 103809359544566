import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { Box, Card, CardContent, CardHeader, Divider, Typography, styled } from '@mui/material';

// constant
const headerSX = {
    zIndex: '9999',
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCardWrap = styled(Card)(({ theme, sx }) => ({
    ...theme.typography.boxBorder,
    ...sx,
    '& .MuiCardContent-root': {
        padding: '16px'
    }
}));

const MainCard = forwardRef(
    ({ children, content = true, contentClass = '', contentSX = {}, darkTitle, secondary, shadow, sx = {}, title, ...others }, ref) => {
        return (
            <MainCardWrap ref={ref} {...others} sx={sx}>
                {/* card header and action */}
                {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
                {darkTitle && title && (
                    <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
                )}

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent className={contentClass}>
                        <Box paddingX={1}>{children}</Box>
                    </CardContent>
                )}
                {!content && children}
            </MainCardWrap>
        );
    }
);

MainCard.propTypes = {
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
