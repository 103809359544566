import React, { lazy } from 'react';
import { InquiryApi } from 'api/community/InquiryApi';
import Loadable from 'components/loading/Loadable';

const List = Loadable(lazy(() => import('./list')));
const View = Loadable(lazy(() => import('./view')));

const InquiryConfig = {
    routes: [
        {
            path: 'inquiry',
            children: [
                {
                    path: '',
                    element: <List />
                },
                {
                    path: 'modify/:code',
                    element: <View />
                }
            ]
        }
    ],
    API: InquiryApi
};

export default InquiryConfig;
