import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { BannerApi } from 'api/content/banner';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const BannerConfig = {
    routes: [
        {
            path: 'banner',
            children: [
                {
                    path: '',
                    element: <List />
                },
                {
                    path: 'create',
                    element: <Create />
                },
                {
                    path: 'modify/:code',
                    element: <Edit />
                }
            ]
        }
    ],
    API: BannerApi
};

export default BannerConfig;
