const { Chip, styled, useTheme } = require('@mui/material');

const ColoredBadge = ({ type, label, ...props }) => {
    const ColoredChip = styled(Chip)(({ theme, sx }) => ({
        ...theme.typography.coloredBadge,
        ...sx
    }));
    const theme = useTheme();

    const colorStatus = (type) => {
        let color = null;

        switch (type) {
            case 'green': {
                color = theme.typography.greenBadge;
                break;
            }
            case 'orange': {
                color = theme.typography.orangeBadge;
                break;
            }
            case 'blue': {
                color = theme.typography.blueBadge;
                break;
            }
            case 'heraBlue': {
                color = theme.typography.heraBlueBadge;
                break;
            }
            case 'red': {
                color = theme.typography.redBadge;
                break;
            }
            case 'grey': {
                color = theme.typography.greyBadge;
                break;
            }
        }
        return color;
    };
    return <ColoredChip label={label} type={type} {...props} sx={colorStatus(type)} />;
};

export default ColoredBadge;
