import { Grid, Typography, useTheme } from '@mui/material';

export const GridContainer = ({ md, children }) => {
    const theme = useTheme();

    return (
        <Grid sx={theme.customStyle.formGridContainer} container item xs={12} md={md ?? 12}>
            {children}
        </Grid>
    );
};

export const GridLabel = ({ md, children, ...other }) => {
    const theme = useTheme();

    return (
        <Grid item md={md ? md : 1.5} xs={4} height={'auto'} {...other}>
            <Typography sx={theme.customStyle.formGridLabel}>{children}</Typography>
        </Grid>
    );
};

export const GridItemField = ({ md, full, children, sx, ...other }) => {
    return (
        <Grid item md={md ? md : 10.5} xs={8} sx={{ p: 1, ...sx }} {...other}>
            {children}
        </Grid>
    );
};

export const GridItem50 = ({ md, xs, full, children, ...other }) => {
    return (
        <Grid item container md={6} xs={12} display={'flex'} alignItems={'center'} {...other}>
            {children}
        </Grid>
    );
};

export const GridLabel50 = ({ md, xs, children, ...other }) => {
    const theme = useTheme();

    return (
        <Grid item md={3} xs={4} height={'100%'} {...other}>
            <Typography sx={theme.customStyle.formGridLabel}>{children}</Typography>
        </Grid>
    );
};

export const GridItemField50 = ({ full, children, sx, ...other }) => {
    return (
        <Grid item md={9} xs={8} sx={{ p: 1, ...sx }} {...other}>
            {children}
        </Grid>
    );
};
