import React, { lazy } from 'react';
import { Loadable } from 'components';
import { ProjectApi } from 'api/project/project';

const ProjectCreatePage = Loadable(lazy(() => import('./create')));
const ProjectEditPage = Loadable(lazy(() => import('./edit')));

const ProjectConfig = {
    routes: [
        {
            path: 'projects',
            children: [
                {
                    path: 'create',
                    element: <ProjectCreatePage />
                },
                {
                    path: 'modify/:code',
                    element: <ProjectEditPage />
                }
            ]
        }
    ],
    API: ProjectApi
};

export default ProjectConfig;
