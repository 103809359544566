// REACT
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Typography, TextField } from '@mui/material';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MultipleStopTwoToneIcon from '@mui/icons-material/MultipleStopTwoTone';

// COMPONENTS
import { FormCmmCodeSelect, SearchInput } from 'components';
import { FormLabelStyle } from 'components/button/style';

// FORM
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

const CustomFilter = forwardRef(({ filters, setOptions, formDefaultValues, isLoading, isFetching }, _) => {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm({
        defaultValues: formDefaultValues ?? { searchWrd: '' }
    });

    if (!filters) {
        filters = [];
    }

    //SEARCH EVENT
    const submit = (values) => {
        let conditions = {};
        Object.keys(values).forEach((key) => {
            conditions[key] = values[key] !== t('ALL') ? values[key] : '';

            if (!conditions['startDate'] || conditions['startDate'] == null) {
                conditions['startDate'] = '';
            }
            if (!conditions['endDate'] || conditions['endDate'] == null) {
                conditions['endDate'] = '';
            }
        });
        setOptions((prevState) => ({ ...prevState, ...conditions }));
    };

    return (
        <Box border="1px solid" borderColor={'#454545'} borderRadius={0} sx={{ mb: 2 }}>
            <form onSubmit={handleSubmit(submit)}>
                {filters && filters.length > 0 && (
                    <Grid
                        container
                        display={{ xs: 'contents', md: 'flex' }}
                        sx={{ ...(filters.length === 1 && FormLabelStyle?.grayBottom) }}
                    >
                        {filters?.map((filter, index) => {
                            return (
                                <Grid
                                    sx={{ ...(filters.length >= 2 && FormLabelStyle?.grayBottom) }}
                                    key={`filter-${index}`}
                                    item
                                    container
                                    xs={12}
                                    md={6}
                                    lg={6}
                                    display={'flex'}
                                    alignItems={'center'}
                                >
                                    <Grid item xs={12} md={3} lg={3} height={'100%'}>
                                        <Typography sx={{ ...(filter?.label && FormLabelStyle?.grayLabel) }}>{t(filter?.label)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={9} sx={{ p: 1 }}>
                                        {filter?.type === 'select' && filter?.commonCode && (
                                            <Controller
                                                control={control}
                                                name={filter?.name}
                                                render={({ field }) => (
                                                    <FormCmmCodeSelect
                                                        all={filter?.value === 'ALL' ? true : false}
                                                        groupName={filter?.commonCode}
                                                        label={t(filter?.label)}
                                                        disabled={isLoading || isFetching}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        )}

                                        {filter?.type === 'select' && filter?.customData && (
                                            <Controller
                                                control={control}
                                                name={filter?.name}
                                                render={({ field }) => (
                                                    <FormCmmCodeSelect
                                                        all={filter?.value === 'ALL' ? true : false}
                                                        customData={filter?.customData}
                                                        label={t(filter?.label)}
                                                        disabled={isLoading || isFetching}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        )}

                                        {filter?.type === 'dateRangePicker' && (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Box display="flex" alignItems="center">
                                                    <Controller
                                                        control={control}
                                                        name={'startDate'}
                                                        defaultValue={''}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                inputFormat="YYYY-MM-DD"
                                                                format={'YYYY-MM-DD'}
                                                                views={['year', 'month', 'day']}
                                                                value={field.value}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        const formattedDate = dayjs(date).format('YYYY-MM-DD');
                                                                        field.onChange(formattedDate);
                                                                    } else {
                                                                        field.onChange('');
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField sx={{ width: '200px' }} size="small" {...params} />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                    <MultipleStopTwoToneIcon fontSize="small" />

                                                    <Controller
                                                        control={control}
                                                        name={'endDate'}
                                                        defaultValue={''}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                inputFormat="YYYY-MM-DD"
                                                                format={'YYYY-MM-DD'}
                                                                views={['year', 'month', 'day']}
                                                                value={field.value}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        const formattedDate = dayjs(date).format('YYYY-MM-DD');
                                                                        field.onChange(formattedDate);
                                                                    } else {
                                                                        field.onChange('');
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField sx={{ width: '200px' }} size="small" {...params} />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </LocalizationProvider>
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}

                <Grid container display={{ xs: 'contents', md: 'flex' }} sx={FormLabelStyle?.grayCol}>
                    <Grid item container xs={12} display={'flex'} alignItems={'center'}>
                        <Grid item md={1.5} xs={12} sx={{ backgroundColor: '#f5f5f5', height: '100%' }}>
                            <Typography sx={FormLabelStyle?.grayLabel}>{t('search')}</Typography>
                        </Grid>
                        <Grid item md={10.5} xs={12} sx={{ p: 1 }}>
                            <Controller
                                control={control}
                                name="searchWrd"
                                render={({ field }) => <SearchInput size="small" disabled={isLoading || isFetching} {...field} />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
});

export default CustomFilter;
