import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const InquiryApi = createApi({
    reducerPath: 'InquiryApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['Inquiries'],
    endpoints: (build) => ({
        getInquiries: build.query({
            query: (params) => `${URL.INQUIRY_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getInquiry: build.query({
            query: (params) => `${URL.INQUIRY_DETAIL}/${params}`,
            providesTags: ['LIST']
        }),
        createInquiry: build.mutation({
            query: (body) => ({
                url: `${URL.INQUIRY_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateInquiry: build.mutation({
            query: (body) => ({
                url: `${URL.INQUIRY_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST']
        }),
        updateContactedInquiry: build.mutation({
            query: (body) => ({
                url: `${URL.INQUIRY_UPDATE_CONTACTED}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST']
        }),
        deleteInquiry: build.mutation({
            query: (body) => ({
                url: `${URL.INQUIRY_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        }),
        deleteInquiries: build.mutation({
            query: (body) => ({
                url: `${URL.INQUIRY_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const {
    useGetInquiriesQuery,
    useCreateInquiryMutation,
    useUpdateInquiryMutation,
    useUpdateContactedInquiryMutation,
    useDeleteInquiryMutation,
    useGetInquiryQuery,
    useDeleteInquiriesMutation
} = InquiryApi;
