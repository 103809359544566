import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { ProjectApi } from 'api/project/project';

const ContractListPage = Loadable(lazy(() => import('./list')));
const ContractEditPage = Loadable(lazy(() => import('../ProjectComponents/DetailForm/edit')));

const ContractConfig = {
    routes: [
        {
            path: 'contract',
            children: [
                {
                    path: '',
                    element: <ContractListPage />
                },
                {
                    path: ':code',
                    element: <ContractEditPage />
                }
            ]
        }
    ],
    API: ProjectApi
};

export default ContractConfig;
