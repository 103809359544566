import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import componentStyleCustom from './compStyleCustom';

export const theme = () => {
    const color = colors;

    const themeOption = {
        colors: color,
        heading: color.darkTextHint1,
        paper: color.paper,
        backgroundDefault: color.grey100,
        background: color.primaryLight,
        darkTextPrimary: color.darkTextHint1,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        divider: color.grey200,
        smallBorderRadius: '4px',
        mediumBorderRadius: '7px',
        mainBorderRadius: '10px',
        secondBorderRadius: '0.5rem',
        mainBoxShadow: 'rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
        boxBoxShadow: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
        boxBorderShadow: 'rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem',
        fontFamily: `'Pretendard', sans-serif`
    };

    const themeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        components: {
            MuiContainer: {
                styleOverrides: {
                    maxWidthSm: {
                        maxWidth: 200
                    },
                    maxWidthMd: {
                        maxWidth: 200
                    },
                    maxWidthLg: {
                        maxWidth: 200
                    }
                }
            }
        },
        typography: themeTypography(themeOption),
        customStyle: componentStyleCustom(themeOption)
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
